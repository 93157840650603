<template>
  <main class="form-signin w-100 m-auto">
    <div>
      <img
        class="mb-4 mt-5 mx-auto d-block"
        src="@/assets/KAI.png"
        alt=""
        width=""
        height="200"
        @click="goHome"
        role="button"
      />
      <h1 class="h3 mb-3 fw-normal text-center">Please sign in</h1>

      <div class="form-floating">
        <input
          type="email"
          class="form-control"
          id="floatingInput"
          placeholder="name@example.com"
          v-model.trim="user.email"
        />
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input
          type="password"
          class="form-control"
          id="floatingPassword"
          placeholder="Password"
          v-model.trim="user.pw"
        />
        <label for="floatingPassword">Password</label>
      </div>

      <!-- <div class="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me" /> Remember me
        </label>
      </div> -->
      <button class="w-100 btn btn-lg btn-primary mt-3" @click="login">
        Sign in
      </button>
      <p class="mt-5 mb-3 text-muted">&copy; KAI Certification Inc. 2023</p>
    </div>
  </main>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      user: {
        email: '',
        pw: '',
        name: ''
      }
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    goHome() {
      this.$router.push({ path: '/' })
    },
    async login() {
      await this.$post('/login', {
        user: this.user
      })
        .then(
          (res) => {
            // 로그인 성공
            // alert(res.data.message)
            this.$swal(res.data.message)
            if (res.data.success === true) {
              this.$store.commit('user/setUser', {
                name: res.data.userData.user_name,
                email: res.data.userData.user_email,
                role: res.data.userData.user_role,
                id: res.data.userData.user_id,
                // pw: res.data.userData.user_pw,
                code: res.data.userData.user_auditable_code,
                yn: res.data.userData.user_yn,
                grade: res.data.userData.user_grade
              })
              this.$router.push({ name: 'AuditSignalRampView' })
            }
          },
          (err) => {
            console.log(err)
            // 로그인 실패
            this.$swal('로그인 실패')
          }
        )
        .catch((err) => {
          this.$swal(err)
        })
    }
  }
}
</script>
<style scoped>
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
